import {
  FC,
  JSX,
  useState,
  Dispatch,
  ChangeEvent,
  SetStateAction,
} from "react";
import { DocumentTableParamsType, RelationDocumentType } from "app/types";
import { CustomInput, ICustomInputProps, ISelectOption } from "../../../../components/CustomInput/CustomInput";
import { tableFiltersData } from "./utils/tableFiltersData";
import css from "./SuppliersTableFilters.module.css";

interface ISuppliersTableFiltersProps {
  isMarkList?: boolean;
  tableParams: DocumentTableParamsType;
  setTableParams: Dispatch<SetStateAction<DocumentTableParamsType >>;
  supplierRegions?: RelationDocumentType[];
  supplierCategories?: RelationDocumentType[];
}

const SuppliersTableFilters: FC<ISuppliersTableFiltersProps> = ({
  isMarkList,
  tableParams,
  setTableParams,
  supplierRegions,
  supplierCategories,
}): JSX.Element => {
  const [searchCategory, setSearchCategory] = useState<string>("");

  const handleInputChange = (inputKey: string, event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void => {
    setTableParams({
      ...tableParams,
      page: 1,
      [inputKey]: event.target.value?.length ? event.target.value : null,
    });
  };

  const handleSelect = (inputKey: string, event: string): void => {
    setTableParams({
      ...tableParams,
      page: 1,
      [inputKey]: event
    });
  };

  const clearFilterParams = (inputKey: string): void => {
    const key: keyof DocumentTableParamsType = inputKey as keyof DocumentTableParamsType;
    const { [key]: _, ...newTableParams }: DocumentTableParamsType = tableParams;

    setTableParams(newTableParams);
  };

  const optionFieldsMap = (
    options?: RelationDocumentType[],
    optionsSchema?: string
  ): ISelectOption[] => {
    return options?.map((option: RelationDocumentType) => {
      const optionInfo: string | undefined = optionsSchema === "regions" ? option.region_name : option.category_name;

      return {
        label: optionInfo,
        value: optionInfo,
      };
    }) as ISelectOption[];
  };
  
  const renderTableFilter = (): JSX.Element[] => tableFiltersData?.map(({
    id,
    type,
    isSearch,
    className,
    placeholder,
    optionsSchema,
    marksPlaceholder,
  }: ICustomInputProps): JSX.Element => {
    const key: keyof DocumentTableParamsType = id as keyof DocumentTableParamsType;
    const docParamKey: string = optionsSchema === "region" ? "region_name" : "category_name";
    const supplierOptions = docParamKey === "region_name"
      ? optionFieldsMap(supplierRegions, "regions")
      : optionFieldsMap(supplierCategories, "");

    return (
      <CustomInput
        id={id}
        key={id}
        type={type}
        value={tableParams[key] ? String(tableParams[key]) : undefined}
        options={supplierOptions}
        isSearch={isSearch}
        className={className}
        allowClear
        showSearch
        searchValue={tableParams[key] ? String(tableParams[key]) : undefined}
        placeholder={isMarkList ? marksPlaceholder : placeholder}
        optionsSchema={optionsSchema}
        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => handleInputChange(id, event)}
        onSelect={(event: string | JSX.Element): void => handleSelect(id, event as string)}
        onClear={(): void => clearFilterParams(id)}
        onSearch={(value: string) => setSearchCategory(value)}
        docsListParams={searchCategory
          ? {[docParamKey]: searchCategory, startswith: docParamKey}
          : {startswith: docParamKey}}
        onBlur={() => setSearchCategory("")}
      />
    );
  });

  return (
    <div className={css.filterContainer}>
      {renderTableFilter()}
    </div>
  );
};

export default SuppliersTableFilters;

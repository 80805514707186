
export const subheadKeys: string[] = ["short_name"];

export const columnWidth: Record<string, string>[] = [
  { "short_name": "20%" },
];

export const sortableColumns: Record<string, boolean> = {
  short_name: true,
  created_at: true,
  fish_criteria: true,
  kitten_criteria: true,
  deadline_criteria: true,
  expectation_criteria: true,
  communication_criteria: true,
  material_security_criteria: true,
  overall_satisfaction_criteria: true,
};

export const ratingKeys: string[] = [
  "deadline_criteria",
  "expectation_criteria",
  "communication_criteria",
  "material_security_criteria",
  "overall_satisfaction_criteria",
];

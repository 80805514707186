import {
  FC,
  JSX,
  useState,
  useEffect,
  ChangeEvent
} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  DocListParams,
  DocumentListResultsType,
  DocumentTableParamsType
} from "app/types";
import { getDocsList } from "../../api/document.api";
import { getSupplierInfo, supplierCreate } from "../../api/supplier.api";
import CustomLoading from "../../components/CustomLoading/CustomLoading";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { validateInn } from "./utils/validateInn";
import { validateOnlyDigits } from "./utils/validateOnlyDigits";
import { validateOnlyLetters } from "./utils/validateOnlyLetters";
import { validateActivityRegion } from "./utils/validateActivityRegion";
import { handleNumericInputChange } from "./utils/handleNumericInputChange";
import { Form, message, Spin, Tooltip } from "antd";

interface ICreateSupplierProps {
}

enum SupplierDataLength {
  InnMax = 12,
  InnMin = 10,
  OgrnMin = 13,
  OgrnMax = 15,
  Kpp = 9,
}

const isRegion: boolean = true;

const CreateSupplier: FC<ICreateSupplierProps> = (): JSX.Element => {
  const [isSubmittable, setSubmittable] = useState<boolean>(false);
  const [isInfoFetching, setInfoFetching] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [supplierInfo1C, setSupplierInfo1C] = useState<DocumentListResultsType | null>(null);
  const [isSupplierNotFound, setSupplierNotFound] = useState<boolean>(false);
  const [isSupplierHasBeenCreated, setSupplierHasBeenCreated] = useState<boolean>(false);
  const [searchRegion, setSearchRegion] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<string>("");

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const navigate: NavigateFunction = useNavigate();

  const isIndividualEntrepreneur: boolean = values?.inn?.length === SupplierDataLength?.InnMax;
  const ogrnName: string = isIndividualEntrepreneur ? "ОГРНИП" : "ОГРН";
  const ogrnLength: number = isIndividualEntrepreneur ? SupplierDataLength?.OgrnMax : SupplierDataLength?.OgrnMin;
  const isCorrectInnLength: boolean = values?.inn?.length >= SupplierDataLength?.InnMin
    && values?.inn?.length <= SupplierDataLength?.InnMax;
  const isBtnDisabled: boolean = !isSubmittable || isSupplierHasBeenCreated || isSupplierNotFound || isFormLoading;
  const shouldHideForm: boolean = !!supplierInfo1C
    && !isInfoFetching
    && !isSupplierNotFound
    && !isSupplierHasBeenCreated
    && isCorrectInnLength;

  const categoryParams: DocListParams = {
    ...(searchCategory ? { category_name: searchCategory } : {}),
    page_size: 100,
    order_by: "category_name",
    startswith: "category_name",
  };

  const regionParams: DocListParams = {
    ...(searchRegion ? { region_name: searchRegion } : {}),
    page_size: 100,
    order_by: "region_name",
    startswith: "region_name",
  };

  const registeredRegionParams: DocListParams = searchRegion
    ? regionParams
    : {order_by: "region_name", startswith: "region_name"};

  useEffect(() => {
    if (isIndividualEntrepreneur) {
      form.setFieldValue("registered_address", null);
    }
  }, [isIndividualEntrepreneur]);

  useEffect(() => {
    if (supplierInfo1C) {
      form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
    }
  }, [supplierInfo1C, form, values]);

  useEffect(() => {
    if (supplierInfo1C) {
      form.setFieldsValue({
        kpp: supplierInfo1C?.КПП,
        ogrn: supplierInfo1C?.РегистрационныйНомер,
        company_name: supplierInfo1C?.НаименованиеПолное,
        registered_address: supplierInfo1C?.ЮридическийАдрес?.Представление,
      });
    }
  }, [supplierInfo1C]);

  useEffect(() => {
    if (isSupplierHasBeenCreated || isSupplierNotFound) {
      form.validateFields(["inn"]);
    } else {
      form.setFields([
        {
          name: "inn",
          errors: [],
        },
      ]);
    }
  }, [isSupplierHasBeenCreated, isSupplierNotFound, form]);

  useEffect(() => {
    if (!values?.inn?.length) {
      onInnClear();
    }
  }, [values?.inn]);

  useEffect(() => {
    if (isCorrectInnLength) {
      setInfoFetching(true);

      const supplierListParams: DocumentTableParamsType = {
        search: values?.inn,
      };

      getDocsList("supplier-counteragent", supplierListParams)
        ?.then((response: any): void => setSupplierHasBeenCreated(!!response?.data?.results?.length))
        ?.catch((error: any): void => console.error("Get suppliers list error", error));

      getSupplierInfo(values?.inn)
        ?.then((response: any) => {
          setSupplierInfo1C(response?.data);
          setSupplierNotFound(false);
          resetNotRequiredFields();
        })
        ?.catch((error: any) => {
          console.error("Fetch supplier from 1C error", error);
          message.error("Ошибка загрузки поставщика из 1С");
          setSupplierNotFound(true);
        })
        ?.finally(() => setInfoFetching(false));
    }
  }, [isCorrectInnLength, isSupplierHasBeenCreated, values?.inn]);

  const closeForm = (): void => {
    navigate("/suppliers");
  };

  const onFinish = (values: any): void => {
    setIsFormLoading(true);

    const newValues = {
      inn: values?.inn,
      supplier_categories: [...values.supplier_categories],
      activity_region: [...values.activity_region],
      kpp: values?.kpp,
      ogrn: values?.ogrn,
      registered_address: values?.registered_address,
      registered_city: values?.registered_city,
      registered_country: values?.registered_country,
      registered_region: values?.registered_region,
      short_name: supplierInfo1C?.НаименованиеСокращенное,
      company_name: supplierInfo1C?.НаименованиеПолное,
    };

    supplierCreate(newValues)
      ?.then(() => {
        //Ставим задержку в полсекунды, чтоб бэк успел обновить данные
        setTimeout(() => {
          closeForm();
          message.success("Поставщик успешно добавлен!");
        }, 2000);
      })
      ?.catch((error: any): void => {
        message.error("Ошибка добавления поставщика");
        console.error("Create supplier mark error", error);
      });
  };

  const onChange = (formItem: string, values: string[]): void => {
    form.setFieldValue(formItem, values);
  };

  const onFormItemClear = (formItem: string): void => {
    form.setFieldValue(formItem, null);
    setSearchRegion("");
    setSearchCategory("");
  };

  const onInnClear = (): void => {
    form.resetFields();
    setSearchRegion("");
    setSearchCategory("");
    onFormItemClear("inn");
    setSubmittable(false);
    setSupplierInfo1C(null);
    setSupplierNotFound(false);
    setSupplierHasBeenCreated(false);
  };

  const resetNotRequiredFields = (): void => {
    form.resetFields([
      "registered_city",
      "activity_region",
      "registered_region",
      "registered_country",
      "supplier_categories",
    ]);
  };

  return (
    <Spin spinning={isFormLoading}>
      <div className="p-4">
        <h1 className="header mb-4">Добавление поставщика</h1>
        <div>
          <div className="p-5 mb-4 bg-white rounded-xl">
            <h2 className="text-base font-medium mb-5">Основные параметры</h2>
            <Form
              form={form}
              name="markForm"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                name="inn"
                label="ИНН"
                validateStatus={isSupplierHasBeenCreated ? "error" : undefined}
                rules={[
                  { required: true },
                  {
                    min: SupplierDataLength.InnMin,
                    message: "ИНН не может быть меньше 10 символов"
                  },
                  {
                    max: SupplierDataLength.InnMax,
                    message: "ИНН не может быть больше 12 символов"
                  },
                  { validator: validateInn(isSupplierHasBeenCreated, isSupplierNotFound) }
                ]}
              >
                <CustomInput
                  id="inn"
                  type="default"
                  allowClear
                  placeholder="Введите ИНН"
                  onClear={onInnClear}
                  onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    handleNumericInputChange(e, form, "inn")}
                  isDebounced={false}
                />
              </Form.Item>
              {shouldHideForm && (
                <>
                  <div className="flex">
                    {!isIndividualEntrepreneur && (
                      <Form.Item
                        name="kpp"
                        label="КПП"
                        className="flex flex-col basis-80 mr-5"
                        rules={[
                          { required: true },
                          {
                            min: SupplierDataLength?.Kpp,
                            message: "КПП должно быть 9 символов"
                          },
                          {
                            max: SupplierDataLength?.Kpp,
                            message: "КПП должно быть 9 символов"
                          },
                          { validator: validateOnlyDigits() }
                        ]}
                      >
                        <CustomInput
                          id="kpp"
                          type="default"
                          allowClear
                          placeholder="Введите КПП"
                          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                            handleNumericInputChange(e, form, "kpp", SupplierDataLength?.Kpp, "КПП")}
                          isDebounced={false}
                          isCounted
                          maxCount={9}
                          onClear={() => onFormItemClear("kpp")}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="ogrn"
                      label={ogrnName}
                      className="flex flex-col basis-80 mr-5"
                      rules={[
                        { required: true },
                        {
                          min: ogrnLength,
                          message: `${ogrnName} должно быть ${ogrnLength} символов`
                        },
                        {
                          max: ogrnLength,
                          message: `${ogrnName} должно быть ${ogrnLength} символов`
                        },
                        { validator: validateOnlyDigits() }
                      ]}
                    >
                      <CustomInput
                        id="ogrn"
                        type="default"
                        allowClear
                        placeholder={`Введите ${ogrnName}`}
                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          handleNumericInputChange(e, form, "ogrn", ogrnLength, ogrnName)}
                        isDebounced={false}
                        isCounted
                        maxCount={ogrnLength}
                        onClear={() => onFormItemClear("ogrn")}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item
                      name="registered_country"
                      label="Страна регистрации"
                      className="flex flex-col basis-80 mr-5"
                      rules={[{ validator: validateOnlyLetters() }]}
                    >
                      <CustomInput
                        id="registered_country"
                        type="default"
                        allowClear
                        placeholder="Введите страну регистрации"
                        onClear={() => onFormItemClear("kpp")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="registered_region"
                      label="Регион"
                      className="flex flex-col basis-80 mr-5"
                    >
                      <CustomInput
                        id="registered_region"
                        type="select"
                        allowClear
                        showSearch
                        optionsSchema="region"
                        placeholder="Выберите регион"
                        onSearch={(value: string) => setSearchRegion(value)}
                        onSelectChange={(values: any) => onChange("registered_region", values)}
                        onClear={() => onFormItemClear("registered_region")}
                        docsListParams={registeredRegionParams}
                        onBlur={() => setSearchRegion("")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="registered_city"
                      label="Населенный пункт"
                      className="flex flex-col basis-80 mr-5"
                      rules={[{ validator: validateOnlyLetters() }]}
                    >
                      <CustomInput
                        id="registered_city"
                        type="default"
                        allowClear
                        placeholder="Введите населенный пункт"
                      />
                    </Form.Item>
                    <Form.Item
                      name="registered_address"
                      label="Адрес"
                      className="flex flex-col basis-80"
                    >
                      <CustomInput
                        id="registered_address"
                        type="default"
                        allowClear
                        placeholder="Введите адрес"
                        onSelectChange={(values: any) => onChange("registered_address", values)}
                        onClear={() => onFormItemClear("registered_address")}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="company_name"
                    label="Наименование поставщика"
                    className="flex flex-col basis-80"
                  >
                    <CustomInput
                      id="company_name"
                      type="default"
                      allowClear
                      placeholder="Введите наименование поставщика"
                      onClear={() => onFormItemClear("company_name")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="activity_region"
                    label="Регионы деятельности"
                    className="flex flex-col basis-80"
                    validateTrigger={["onSelectChange"]}
                    required
                    rules={[{ validator: validateActivityRegion(values?.activity_region, isRegion) }]}
                  >
                    <CustomInput
                      id="activity_region"
                      type="select"
                      mode="multiple"
                      allowClear
                      optionsSchema="region"
                      placeholder="Выберите регионы"
                      onSearch={(value: string) => setSearchRegion(value)}
                      onSelectChange={(values: any) => onChange("activity_region", values)}
                      onClear={() => onFormItemClear("activity_region")}
                      docsListParams={registeredRegionParams}
                      onBlur={() => setSearchRegion("")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="supplier_categories"
                    label="Категории"
                    className="flex flex-col basis-80"
                    validateTrigger={["onSelectChange"]}
                    required
                    rules={[{ validator: validateActivityRegion(values?.supplier_categories) }]}
                  >
                    <CustomInput
                      id="supplier_categories"
                      type="select"
                      mode="multiple"
                      allowClear
                      optionsSchema="category"
                      placeholder="Выберите категории"
                      onSearch={(value: string) => setSearchCategory(value)}
                      onSelectChange={(values: any) => onChange("supplier_categories", values)}
                      onClear={() => onFormItemClear("supplier_categories")}
                      docsListParams={searchCategory
                        ? categoryParams
                        : {order_by: "category_name", startswith: "category_name"}}
                      onBlur={() => setSearchCategory("")}
                    />
                  </Form.Item>
                </>
              )}
              {isInfoFetching && (
                <div className="flex items-center justify-center h-1/4 bg-[#F7F8F9] rounded-xl">
                  <CustomLoading className="my-20" defaultIcon />
                </div>
              )}
            </Form>
          </div>
          <div className="p-5 bg-white rounded-xl">
            <Form
              form={form}
              name="markForm"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item noStyle>
                <div className="text-right">
                  <CustomButton
                    className="mr-2"
                    type="default"
                    size="large"
                    text="Отменить"
                    ghost
                    onClick={closeForm}
                  />
                  <Tooltip title={isSupplierHasBeenCreated && "Поставщик уже добавлен"}>
                    <>
                      <CustomButton
                        type="primary"
                        size="large"
                        text="Подтвердить"
                        htmlType="submit"
                        disabled={isBtnDisabled}
                      />
                    </>
                  </Tooltip>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CreateSupplier;

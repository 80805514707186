import axios from "../utils/axios";

const API_DOCUMENT: string | undefined = process.env.REACT_APP_API_TASKS_URL?.length
  ? process.env.REACT_APP_API_TASKS_URL
  : "https://dev.pipeline.smbconnect.ru";

// Ручка получения данных по поставщику из 1С
export const getSupplierInfo = async (inn: string): Promise<any> => {
  return axios.GET(API_DOCUMENT, `api/fin/company/v1/details/${inn}`);
};

// Ручка создания поставщика
export const supplierCreate = async (params: any): Promise<any> => {
  return axios.POST(API_DOCUMENT, "api/or/v1/supplier/create", params);
};

// Ручка выставление оценки поставщику
export const supplierMarkCreate = async (params: any): Promise<any> => {
  return axios.POST(API_DOCUMENT, "api/or/v1/supplier-marks/create", params);
};
